<template>
  <div
    class="w-fit relative inline-block z-50"
    @mouseover="openPopover"
    @mouseleave="closePopover"
  >
    <div class="mt-[3px] inline-flex">
      <IconsWarning class="w-[15px]" />
    </div>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div
        v-if="popoverVisible"
        class="absolute left-full top-0 z-10 w-auto min-w-[15rem] -translate-x-1/2 transform p-6 ml-24 z-50"
      >
        <div
          class="overflow-hidden rounded shadow-lg ring-1 ring-black ring-opacity-5 z-50"
        >
          <div class="relative min-w-[250px] bg-white p-6">
            <p class="text-xs leading-4 indent-3 text-justify">
              {{ t('estimated_price') }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
const { t } = useI18n()
const popoverVisible = ref(false)

const openPopover = () => {
  popoverVisible.value = true
}

const closePopover = () => {
  popoverVisible.value = false
}
</script>
